import { defineStore } from 'pinia';
import { useApi } from '../services/api';
import { StoreNames } from '../shared/store-names';

interface State {
  name: string;
  locale: string;
  currency: string;
  wh_replenishment_active: boolean;
  timezone_name: string;
  timezone_offset_in_hours: number;
  custom_data_schema: {
    [scope: string]: [{ key: string; type: 'string' | 'float' | 'boolean' | 'datetime' }];
  };
}

export const useAccountStore = defineStore(StoreNames.Account, {
  state: (): State => ({
    name: '',
    locale: '',
    currency: '',
    wh_replenishment_active: false,
    timezone_name: 'UTC',
    timezone_offset_in_hours: 0,
    custom_data_schema: {},
  }),
  actions: {
    async fetch(): Promise<void> {
      const api = useApi();

      const { data } = await api.getAccountSettings();

      if (!data) {
        return;
      }

      this.name = data.name;
      this.locale = data.locale;
      this.currency = data.currency;
      this.timezone_name = data.timezone_name ?? 'UTC';
      this.timezone_offset_in_hours = data.timezone_offset_in_hours ?? 0;
      this.custom_data_schema = data.custom_data_schema ?? {};
      this.wh_replenishment_active = data.wh_replenishment_active;
    },
    async clear(): Promise<void> {
      this.$reset();
    },
  },
});
