import { defineStore } from 'pinia';
import { z } from 'zod';
import { StoreNames } from '../../../shared/store-names';

interface State {
  visibleMetrics: string[];
  readonly __v: number;
}

function getState(): State {
  return {
    visibleMetrics: ['at_site', 'in_transit', 'optimal_stock', 'sale'],
    __v: 0,
  };
}

function getSchema() {
  return z.object({
    visibleMetrics: z.array(z.string()),
  });
}

const migrations = [
  // v1 - save data from the old app setting store
  function v1(state: any) {
    const oldState = window.localStorage.getItem('onebeat-app:app-settings');

    if (!oldState) {
      return {};
    }

    const parsedState = JSON.parse(oldState);

    const { visibleMetrics } = parsedState.skuChart;

    return {
      ...state,
      visibleMetrics,
    };
  },
];

export const useSkuChartStore = defineStore(StoreNames.SkuChart, {
  state: getState,
  persist: {
    afterRestore: ({ store }) => {
      // migration
      for (let i = store.__v; i < migrations.length; i++) {
        if (migrations[i]) {
          try {
            store.$state = migrations[i]({ ...store.$state });
          } catch (err) {
            // TODO: log?
          }
          store.__v = i + 1;
        }
      }

      const schema = getSchema();
      store.$state = schema.parse(store.$state);
      store.$persist();
    },
  },
});
