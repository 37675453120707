import { defineStore } from 'pinia';
import { StoreNames } from '../../../shared/store-names';

enum Columns {
  SkuChart = 'sku_chart',
  StoreName = 'store_name',
  StoreExternalId = 'store_external_id',
  SkuName = 'sku_name',
  SkuId = 'sku_id',
  AtWh = 'at_wh',
  SaleRate = 'sale_rate',
  OptimalStock = 'optimal_stock',
  Coverage = 'Coverage',
  LastSiteQuantity = 'last_site_qty',
  LastTransitQuantity = 'last_transit_qty',
  Replenishment = 'replenishment',
  InventoryNeeded = 'inventory_needed',
  Stock = 'stock',
  ExpectedCoverage = 'expected_coverage',
  ReplenishmentAdjustment = 'replenishment_adjustment',
}

interface State {
  sorting: {
    sortBy: string | null;
    sortOrder: 'asc' | 'desc';
  };
  columnsVisibility: Record<string, boolean>;
  columnsOrder: string[];
}

export const useReplenishmentInventoriesPageStore = defineStore(
  StoreNames.ReplenishmentInventoriesPage,
  {
    state: (): State => ({
      sorting: {
        sortBy: null,
        sortOrder: 'asc',
      },
      columnsVisibility: {
        [Columns.SkuChart]: true,
        [Columns.StoreName]: true,
        [Columns.StoreExternalId]: true,
        [Columns.SkuName]: true,
        [Columns.SkuId]: false,
        [Columns.AtWh]: false,
        [Columns.SaleRate]: true,
        [Columns.OptimalStock]: true,
        [Columns.Coverage]: false,
        [Columns.LastSiteQuantity]: false,
        [Columns.LastTransitQuantity]: false,
        [Columns.Replenishment]: false,
        [Columns.InventoryNeeded]: true,
        [Columns.Stock]: true,
        [Columns.ExpectedCoverage]: false,
        [Columns.ReplenishmentAdjustment]: true,
      },
      columnsOrder: [
        Columns.SkuChart,
        Columns.StoreName,
        Columns.StoreExternalId,
        Columns.SkuName,
        Columns.SkuId,
        Columns.AtWh,
        Columns.SaleRate,
        Columns.OptimalStock,
        Columns.Coverage,
        Columns.LastSiteQuantity,
        Columns.LastTransitQuantity,
        Columns.Replenishment,
        Columns.InventoryNeeded,
        Columns.Stock,
        Columns.ExpectedCoverage,
        Columns.ReplenishmentAdjustment,
      ],
    }),
    persist: true,
  },
);
