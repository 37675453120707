import { Auth, type AuthOptions } from './auth';
import { Transport } from './transport';
import { Meilisearch } from './meilisearch';
import type { Upload, AccountSettings, PaginationQuery } from './interfaces';

export interface ApiServiceOptions {
  baseUrl: string;
  meilisearchHost: string;
  keycloak: Omit<AuthOptions, 'transport'>;
}

export class ApiService {
  readonly transport: Transport;
  readonly auth: Auth;
  readonly meilisearch: Meilisearch;

  constructor(options: ApiServiceOptions) {
    this.transport = new Transport({
      baseUrl: options.baseUrl,
      getAccessToken: () => {
        return this.auth.getToken();
      },
      authErrorHandler: () => {
        this.auth.logout();
      },
    });
    this.auth = new Auth({ ...options.keycloak, transport: this.transport });
    this.meilisearch = new Meilisearch({
      host: options.meilisearchHost,
      auth: this.auth,
      transport: this.transport,
    });
  }

  getSystemServicesStatus() {
    return this.transport.get<{ Meilisearch: boolean }>('v1/system_services');
  }

  getAccountSettings() {
    return this.transport.get<AccountSettings>('v1/account');
  }

  getUploads(query?: PaginationQuery) {
    return this.transport.get<Upload[]>('/v1/uploads', {
      query,
    });
  }
}
