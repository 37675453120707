import { defineStore } from 'pinia';
import { StoreNames } from '../../../shared/store-names';

interface State {
  visibleMetrics: { [key: string]: string[] };
  appliedFilters: { [key: string]: { [key: number]: string[] | string | null } };
}

export const useSupersetStore = defineStore(StoreNames.Superset, {
  state: (): State => ({
    visibleMetrics: {},
    appliedFilters: {},
  }),
  persist: true,
});
