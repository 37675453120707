<script lang="ts" setup>
import { ref, watch } from 'vue';
import { useToaster } from './use-toaster';
import ObToastsItem from './ob-toasts-item.vue';

const { toasts } = useToaster();

const lastToastLeaving = ref(false);

watch(
  () => toasts.value.length,
  (value, oldValue) => {
    if (value === oldValue) {
      return;
    }

    lastToastLeaving.value = value === 0;
  },
  {
    flush: 'pre',
  },
);

function onAfterLeave() {
  lastToastLeaving.value = false;
}
</script>

<template>
  <Teleport v-if="toasts.length || lastToastLeaving" to="body">
    <div :class="$style.root">
      <TransitionGroup
        appear
        tag="div"
        :class="$style.list"
        :move-class="$style.move"
        :enter-from-class="$style.enterFrom"
        :enter-active-class="$style.enterActive"
        :leave-active-class="$style.leaveActive"
        :leave-to-class="$style.leaveTo"
        @after-leave="onAfterLeave"
      >
        <div v-for="toast in toasts" :key="toast.id" :class="$style.item">
          <ObToastsItem :toast="toast" />
        </div>
      </TransitionGroup>
    </div>
  </Teleport>
  <slot />
</template>

<style lang="scss" module>
@use '../../styles/shared';

.root {
  position: fixed;
  left: 50%;
  top: 24px;
  display: flex;
  flex-direction: column;
  max-width: 560px;
  padding: 0 24px;
  width: 100%;
  box-sizing: border-box;
  transform: translateX(-50%);
  z-index: shared.$z-index-toast;
  pointer-events: none;

  > * {
    pointer-events: auto;
  }
}

.list {
  position: relative;
}

.item {
  width: 100%;
  margin-bottom: 8px;
}

.enterFrom,
.leaveTo {
  opacity: 0;
  transform: translateY(-30px);
}

.move,
.enterActive,
.leaveActive {
  transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
}
.leaveActive {
  position: absolute;
}
</style>
