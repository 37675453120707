<script lang="ts" setup>
import ObConfirmDialogItem from './ob-confirm-dialog-item.vue';
import { useConfirmDialog } from './use-confirm-dialog';

const { dialogs } = useConfirmDialog();
</script>

<template>
  <div v-if="dialogs.length">
    <div v-for="dialog in dialogs" :key="dialog.id">
      <ObConfirmDialogItem :dialog="dialog" />
    </div>
  </div>
</template>
