import { computed } from 'vue';
import type { MaybeRef, ComputedRef } from 'vue';
import { useStack } from '../use-stack';

interface UseZIndexReturn {
  zIndex: ComputedRef<number | undefined>;
}

interface UseZIndexOptions {
  active: MaybeRef<boolean>;
  size?: number;
}

const BASE = 2000;
const STACK_NAME = Symbol(__DEV__ ? 'z-index stack' : '');

export function useZIndex(options: UseZIndexOptions): UseZIndexReturn {
  const { index, exists, first, stack } = useStack<{ size: number }>(STACK_NAME, {
    active: options.active,
    payload: {
      size: options?.size ?? 1,
    },
  });

  const zIndex = computed<number | undefined>(() => {
    if (!exists.value) {
      return undefined;
    }

    if (first.value) {
      return BASE;
    }

    return (
      BASE + stack.value.slice(0, index.value).reduce((acc, { payload }) => acc + payload.size, 0)
    );
  });

  return {
    zIndex,
  };
}
