export const typography = {
  fontFamilyPrimary: 'Poppins, sans-serif',
};

export const colors = {
  action: '#f42287',
  primary: '#021148',
  primary80: '#35416D',
  surface60: '#677091',
  surface40: '#9AA0B6',
  surface24: '#C2C6D3',
  surface16: '#D7D9E2',
  surface6: '#F0F1F4',
  surface4: '#F6F6F8',
  surface0: '#FFFFFF',
};
