import { defineStore } from 'pinia';
interface State {
  sidebarCollapsed: boolean;
}

export const useAppSettingsStore = defineStore('app-settings', {
  state: (): State => ({
    sidebarCollapsed: false,
  }),
  persist: true,
});
