import { reactive, ref } from 'vue';
import type { Plugin } from 'vue';
import { createUniqueId } from '../../utils';
import { CONFIRM_DIALOG_API, ConfirmDialog, ConfirmDialogOptions } from './shared';
export { default as ObConfirmDialog } from './ob-confirm-dialogs.vue';
export * from './use-confirm-dialog';

export function createConfirmDialog(): Plugin {
  return {
    install(app) {
      const dialogs = ref<ConfirmDialog[]>([]);

      function remove(id: string) {
        const dialog = dialogs.value.find((dialog) => dialog.id === id);

        if (!dialog) {
          return;
        }

        dialogs.value = dialogs.value.filter((dialog) => dialog.id !== id);
      }

      function create(dialogOptions: ConfirmDialogOptions): ConfirmDialog {
        const id = createUniqueId();
        const dialogData: ConfirmDialog = reactive({
          text: dialogOptions?.text ?? '',
          title: dialogOptions?.title ?? '',
          confirmText: dialogOptions?.confirmText ?? 'Confirm',
          cancelText: dialogOptions?.cancelText ?? 'Cancel',
          onConfirm: dialogOptions?.onConfirm ?? null,
          onCancel: dialogOptions?.onCancel ?? null,
          id,

          remove: () => {
            remove(id);
          },
        });

        dialogs.value.push(dialogData);

        return dialogData;
      }

      app.provide(CONFIRM_DIALOG_API, {
        dialogs,
        remove,
        create,
      });
    },
  };
}
