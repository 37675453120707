import axios from 'axios';
import { defineStore } from 'pinia';
import { StoreNames } from '../../../shared/store-names';
import { useI18nStore } from '../../../store';
import { config } from '../../../app';

interface Keys {
  [key: string]: {
    text: string;
    description: string;
  };
}

interface State {
  keys: Keys;
}

interface MetastoreResponse {
  data: Array<{ key: string; translations: Array<{ text: string; description: string }> }>;
}

export const useSupersetKeyMetaStore = defineStore(StoreNames.SupersetKeyMeta, {
  state: (): State => ({
    keys: {},
  }),
  actions: {
    async fetch(): Promise<void> {
      const i18nStore = useI18nStore();

      const { data } = await axios.get<MetastoreResponse>(
        `/items/keys?fields[]=key,translations.text,translations.description&deep[translations][_filter][languages_code][_eq]=${i18nStore.locale}&limit=999`,
        {
          baseURL: config.superset.metastoreBaseUrl,
        },
      );

      this.keys = data.data.reduce<Keys>((acc, item) => {
        acc[item.key] = {
          text: item.translations[0]?.text,
          description: item.translations[0]?.description,
        };

        return acc;
      }, {});
    },
    async clear(): Promise<void> {
      this.$reset();
    },
  },
});
