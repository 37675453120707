<script lang="ts" setup>
import { computed } from 'vue';
import { IconLoader2 } from '@tabler/icons-vue';
import { pxOrValue, hasSlotContent } from '../../utils';

interface Props {
  color?: string;
  size?: number | string;
}

const props = withDefaults(defineProps<Props>(), {
  color: undefined,
  size: undefined,
});

const cssVariables = computed(() => ({
  '--spinner-size': pxOrValue(props.size),
  '--spinner-color': props.color,
}));
</script>

<template>
  <div role="progressbar" :class="$style.root" :style="cssVariables">
    <div :class="$style.icon">
      <IconLoader2 />
    </div>
    <div v-if="hasSlotContent($slots.default)" :class="$style.text">
      <slot />
    </div>
  </div>
</template>

<style lang="scss" module>
@use '../../styles/colors';
@use '../../styles/iconography';

.root {
  display: inline-flex;
  vertical-align: top;
  align-items: center;
  --spinner-size: 24px;
  --spinner-color: #907ff5; // TODO: design token
}
.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: iconography.$icon-size-m;
  width: var(--spinner-size);
  height: var(--spinner-size);
  animation: spinner 1s linear infinite;
  color: var(--spinner-color);

  > svg {
    width: 100%;
    height: 100%;
  }
}

.text {
  font-size: 16px;
  line-height: 24px;
  margin-left: 12px;
  color: colors.$primary;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
