import {
  Chart,
  BarController,
  BarElement,
  CategoryScale,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  Filler,
  PieController,
  ArcElement,
} from 'chart.js';
import type { Defaults } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import zoomPlugin from 'chartjs-plugin-zoom';
import { merge } from 'lodash-es';
import { designTokens } from '../../ui-kit';
import type { DeepPartial } from '../../shared/types';

// https://www.chartjs.org/docs/latest/getting-started/integration.html
Chart.register(
  BarController,
  BarElement,
  CategoryScale,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  Filler,
  PieController,
  ArcElement,
  annotationPlugin,
  zoomPlugin,
);

const defaults: DeepPartial<Defaults> = {
  datasets: {
    line: {
      spanGaps: true,
    },
  },
  elements: {
    bar: {
      borderWidth: 0,
      hoverBorderWidth: 0,
    },
    line: {
      borderWidth: 4,
      tension: 0.25,
      cubicInterpolationMode: 'monotone',
    },
    point: {
      borderWidth: 4,
      hoverBorderWidth: 4,
      radius: 4,
    },
    arc: {
      borderWidth: 0,
    },
  },
  interaction: {
    axis: 'x',
    intersect: false,
    mode: 'index',
  },
  layout: {
    padding: 0,
  },
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
  responsive: true,
  scales: {
    category: {
      grid: {
        drawOnChartArea: false,
        tickLength: 4,
        color: designTokens.colors.surface16,
        tickColor: designTokens.colors.surface16,
      },
      border: {
        color: designTokens.colors.surface16,
      },
      ticks: {
        font: {
          family: '"Poppins", sans-serif',
          size: 12,
          weight: '500',
        },
        color: '#35416D',
        padding: 12,
      },
      title: {
        font: {
          family: designTokens.typography.fontFamilyPrimary,
          size: 12,
          weight: '400',
        },
        color: designTokens.colors.surface40,
        align: 'end',
      },
    },
    linear: {
      grid: {
        drawOnChartArea: false,
        tickLength: 4,
        color: designTokens.colors.surface16,
        tickColor: designTokens.colors.surface16,
      },
      border: {
        color: designTokens.colors.surface16,
      },
      ticks: {
        font: {
          family: '"Poppins", sans-serif',
          size: 12,
          weight: '500',
        },
        color: designTokens.colors.surface40,
        padding: 8,
      },
      title: {
        font: {
          family: designTokens.typography.fontFamilyPrimary,
          size: 12,
          weight: '400',
        },
        color: designTokens.colors.surface40,
        align: 'end',
      },
    },
  },
};

merge(Chart.defaults, defaults);
