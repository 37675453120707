import { getCurrentInstance, inject } from 'vue';
import type { Plugin } from 'vue';
import { ApiService } from './api-service';
import type { ApiServiceOptions } from './api-service';
import { API_SYMBOL } from './constants';

let instance: ApiService;

export function createApi(options: ApiServiceOptions): Plugin {
  if (instance) {
    throw new Error('Api service was already initialized');
  }

  instance = new ApiService(options);

  return {
    install(app) {
      app.provide(API_SYMBOL, instance);
    },
  };
}

export function useApi(): ApiService {
  const api = getCurrentInstance() ? inject(API_SYMBOL) : instance;

  if (!api) {
    throw new Error('Init Api service before use it');
  }

  return api;
}
