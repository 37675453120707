import type { InjectionKey, FunctionalComponent, Component, Ref } from 'vue';
import type { Status } from '../../shared/types';

type ToastVariant = Status | null;

export interface Toast {
  actions: ToastAction[]; // eslint-disable-line no-use-before-define
  closable: boolean;
  content: string;
  duration: number;
  readonly id: string;
  keepAliveOnHover: boolean;
  key: string | symbol | null;
  icon: Component | FunctionalComponent | null;
  loading: boolean;
  removed: boolean;
  variant: ToastVariant;
  readonly remove: () => void;
  onRemoved?: (toast: Toast) => void;
}

interface ToastAction {
  label: string;
  callback: (toast: Toast) => void;
}

export interface ToastOptions {
  actions?: ToastAction[];
  closable?: boolean;
  duration?: number;
  icon?: Component | FunctionalComponent;
  key?: string | symbol;
  keepAliveOnHover?: boolean;
  loading?: boolean;
  variant?: ToastVariant;
  onRemoved?: (toast: Toast) => void;
}

interface ToasterApi {
  toasts: Ref<Toast[]>;
  create: (content: string, options?: ToastOptions) => Toast;
  info: (content: string, options?: ToastOptions) => Toast;
  success: (content: string, options?: ToastOptions) => Toast;
  warning: (content: string, options?: ToastOptions) => Toast;
  danger: (content: string, options?: ToastOptions) => Toast;
  remove: (id: string) => void;
  removeAll: () => void;
}

export const TOASTER_API: InjectionKey<ToasterApi> = Symbol(__DEV__ ? 'Toaster API' : '');
