<script lang="ts" setup>
import type { Spacing } from '../../shared/types';

interface Props {
  spacing?: Spacing;
  vertical?: boolean;
  noWrap?: boolean;
  alignX?: 'start' | 'center' | 'end' | 'stretch';
  alignY?: 'start' | 'center' | 'end' | 'stretch' | 'baseline';
}

const props = withDefaults(defineProps<Props>(), {
  spacing: undefined,
  vertical: false,
  noWrap: false,
  alignX: undefined,
  alignY: undefined,
});
</script>

<template>
  <div
    class="ob-space"
    :class="{
      [`_spacing-${props.spacing}`]: props.spacing,
      _vertical: props.vertical,
      '_no-wrap': props.noWrap,
      [`_align-x-${props.alignX}`]: props.alignX,
      [`_align-y-${props.alignY}`]: props.alignY,
    }"
  >
    <slot />
  </div>
</template>

<style lang="scss">
@use 'sass:map';
@use '../../styles/spacing';
@use '../../styles/colors';

.ob-space {
  --spacing: 0;

  box-sizing: border-box;
  display: flex;
  vertical-align: middle;
  gap: var(--spacing);

  &._vertical {
    width: 100%;
    flex-direction: column;
  }

  &:not(._vertical) {
    flex-wrap: wrap;

    &._no-wrap {
      flex-wrap: nowrap;
    }

    &._align-x {
      &-start {
        justify-content: start;
      }

      &-end {
        justify-content: end;
      }

      &-center {
        justify-content: center;
      }
    }

    &._align-y {
      &-start {
        align-items: start;
        align-content: start;
      }

      &-end {
        align-items: end;
        align-content: end;
      }

      &-center {
        align-items: center;
        align-content: center;
      }

      &-stretch {
        align-items: stretch;
        align-content: stretch;
      }

      &-baseline {
        align-items: baseline;
        align-content: baseline;
      }
    }
  }

  &._vertical {
    &._align-x {
      &-start {
        align-items: start;
      }

      &-end {
        align-items: end;
      }

      &-center {
        align-items: center;
      }

      &-stretch {
        align-items: stretch;
      }
    }

    &._align-y {
      &-start {
        justify-content: start;
      }

      &-end {
        justify-content: end;
      }

      &-center {
        justify-content: center;
      }
    }
  }

  @each $spacing-key in map.keys(spacing.$spacers) {
    $spacing-value: map.get(spacing.$spacers, $spacing-key);

    &._spacing-#{$spacing-key} {
      --spacing: #{$spacing-value};
    }
  }
}
</style>
