// We can add more but it makes no sense
// https://en.wikipedia.org/wiki/Right-to-left_script
const RTL_LOCALES = ['ar', 'fa', 'he'];

export function getTextDirection(locale: string): 'ltr' | 'rtl' {
  const localeObject = new Intl.Locale(locale);

  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/Locale/textInfo
  if ('textInfo' in localeObject) {
    return (localeObject.textInfo as any).direction;
  }

  return RTL_LOCALES.includes(localeObject.language) ? 'rtl' : 'ltr';
}
