import type { Plugin } from 'vue';

interface Heap {
  appid: string;
  config: any; // TODO: type
  userId: string;
  identity: string | null;
  addEventProperties: (properties: Record<string, string | number>) => void;
  addUserProperties: (properties: Record<string, string | number>) => void;
  clearEventProperties: () => void;
  identify: (identity: string) => void;
  resetIdentity: () => void;
  removeEventProperty: (property: string) => void;
  track: (name: string, properties?: Record<string, string | number>) => void;
}

declare global {
  interface Window {
    heap: Heap & Array<unknown>;
  }
}

function create(appId: string, config = {}) {
  window.heap = window.heap || [];
  window.heap.appid = appId;
  window.heap.config = config;

  const methods: Array<keyof Heap> = [
    'addEventProperties',
    'addUserProperties',
    'clearEventProperties',
    'identify',
    'resetIdentity',
    'removeEventProperty',
    'track',
  ];

  methods.forEach((method) => {
    window.heap[method] = function (...args: unknown[]) {
      if (window.heap.push) {
        window.heap.push([method].concat(Array.prototype.slice.call(args, 0)));
      }
    };
  });
}

function load(appid: string) {
  const el = document.createElement('script');
  el.type = 'text/javascript';
  el.async = true;
  el.src = `https://cdn.heapanalytics.com/js/heap-${appid}.js`;
  document.head.appendChild(el);
}

export function createHeap(appId: string, config = {}): Plugin {
  return {
    install() {
      create(appId, config);
      load(appId);
    },
  };
}

export function getHeap(): Heap | undefined {
  return window.heap;
}
