import { watch } from 'vue';
import { useStorage } from '@vueuse/core';
import { useRegisterSW } from 'virtual:pwa-register/vue';
import { useI18n } from '../services/i18n';
import { useToaster } from '../ui-kit';

export function useSW() {
  const toaster = useToaster();

  // Hybrid app update strategy
  // - Check for updates on init and every n minutes
  // - Suggest an immediate update
  // - Schedule auto update for the next app load
  const autoRefreshScheduled = useStorage<null | boolean>('auto-refresh-sw', null);
  const { needRefresh, updateServiceWorker } = useRegisterSW({
    immediate: true,
    async onRegisteredSW(url, registration) {
      if (autoRefreshScheduled.value) {
        await updateServiceWorker();
        autoRefreshScheduled.value = null;
      }

      setInterval(
        () => {
          if (registration) {
            console.debug('[Onebeat]: Checking for the app updates...');
            registration?.update();
          }
        },
        1000 * 60 * 5,
      );
    },
  });

  const { t } = useI18n();

  const refreshToastKey = Symbol('New app version');

  watch(needRefresh, (value) => {
    if (value && !autoRefreshScheduled.value) {
      toaster.create(t('app.messages.newAppVersionIsAvailable'), {
        key: refreshToastKey,
        duration: 0,
        actions: [
          {
            label: t('app.actions.update'),
            callback(toast) {
              autoRefreshScheduled.value = null;
              updateServiceWorker();
              toast.remove();
            },
          },
          {
            label: t('app.actions.postpone'),
            callback(toast) {
              toast.remove();
            },
          },
        ],
      });

      autoRefreshScheduled.value = true;
    }
  });
}
