<script lang="ts" setup>
interface ObModalOverlayProps {
  zIndex?: number;
}

const props = withDefaults(defineProps<ObModalOverlayProps>(), {
  zIndex: undefined,
});
</script>

<template>
  <div class="ob-modal-overlay" :style="{ zIndex: props.zIndex }" />
</template>

<style lang="scss" scoped>
.ob-modal-overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(#000, 0.25);
}
</style>
