import type { InjectionKey, Ref } from 'vue';

export interface ConfirmDialog {
  onConfirm: ((dialog: ConfirmDialog) => void) | null;
  onCancel: ((dialog: ConfirmDialog) => void) | null;
  text: string;
  confirmText: string;
  cancelText: string;
  title: string;
  readonly id: string;
  readonly remove?: () => void;
}

export interface ConfirmDialogOptions {
  onConfirm?: ((dialog: ConfirmDialog) => void) | null;
  onCancel?: ((dialog: ConfirmDialog) => void) | null;
  confirmText?: string;
  cancelText?: string;
  text?: string;
  title?: string;
}

interface ConfirmDialogApi {
  dialogs: Ref<ConfirmDialog[]>;
  create: (options: ConfirmDialogOptions) => ConfirmDialog;
  remove: (id: string) => void;
}
export const CONFIRM_DIALOG_API: InjectionKey<ConfirmDialogApi> = Symbol(
  __DEV__ ? 'Confirm dialog API' : '',
);
