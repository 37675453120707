import { injectStrict } from '../../utils';
import { CONFIRM_DIALOG_API } from './shared';

export function useConfirmDialog() {
  return injectStrict(
    CONFIRM_DIALOG_API,
    undefined,
    'useConfirmDialog was called with no installed ConfirmDialog',
  );
}
