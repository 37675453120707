import { ref, reactive } from 'vue';
import type { Plugin } from 'vue';
import { createUniqueId } from '../../utils';
import { TOASTER_API } from './shared';
import type { Toast, ToastOptions } from './shared';
export { default as ObToasts } from './ob-toasts.vue';
export * from './use-toaster';

interface Options {
  duration?: number;
  keepAliveOnHover?: boolean;
}

export function createToaster(options?: Options): Plugin {
  return {
    install(app) {
      const toasts = ref<Toast[]>([]);

      function remove(id: string) {
        const toast = toasts.value.find((toast) => toast.id === id);

        if (!toast) {
          return;
        }

        toasts.value = toasts.value.filter((toast) => toast.id !== id);

        toast.removed = true;
      }

      function removeAll() {
        toasts.value = [];
      }

      function create(content: string, toastOptions?: ToastOptions): Toast {
        if (toastOptions?.key) {
          toasts.value
            .filter((toast) => toast.key === toastOptions.key)
            .forEach((toast) => toast.remove());
        }

        const id = createUniqueId();

        const toast: Toast = reactive({
          actions: toastOptions?.actions ?? [],
          closable: toastOptions?.closable ?? false,
          content,
          duration: toastOptions?.duration ?? options?.duration ?? 3000,
          id,
          keepAliveOnHover: options?.keepAliveOnHover ?? false,
          key: toastOptions?.key ?? null,
          icon: toastOptions?.icon ?? null,
          loading: toastOptions?.loading ?? false,
          removed: false,
          variant: toastOptions?.variant ?? null,
          onRemoved: toastOptions?.onRemoved,
          remove: () => {
            remove(id);
          },
        });

        toasts.value.push(toast);

        return toast;
      }

      app.provide(TOASTER_API, {
        toasts,
        create,
        info(content: string, options?: ToastOptions) {
          return create(content, { ...options, variant: 'info' });
        },
        success(content: string, options?: ToastOptions) {
          return create(content, { ...options, variant: 'success' });
        },
        warning(content: string, options?: ToastOptions) {
          return create(content, { ...options, variant: 'warning' });
        },
        danger(content: string, options?: ToastOptions) {
          return create(content, { ...options, variant: 'danger' });
        },
        remove,
        removeAll,
      });
    },
  };
}
