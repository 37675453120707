import { inject, InjectionKey } from 'vue';

export function injectStrict<T>(key: InjectionKey<T>, fallback?: T, errorMessage?: string): T {
  const resolved = inject(key, fallback);

  if (!resolved) {
    const error = new Error(errorMessage ?? `Could not resolve ${key.toString()}`);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(error, injectStrict);
    }
    throw error;
  }

  return resolved;
}
