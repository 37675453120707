import { defineStore } from 'pinia';
import { ZodRawShape, z } from 'zod';
import { StoreNames } from '../../../shared/store-names';

enum Columns {
  SkuChart = 'sku_chart',
  Size = 'size',
  SkuName = 'sku_name',
  SkuExternalId = 'sku_external_id',
  Replenishment = 'replenishment',
  Sold = 'sold',
  SalesRate = 'salesRate',
  Stock = 'stock',
  OptimalStock = 'optimalStock',
  WarehouseInventory = 'warehouseInventory',
  WhNames = 'warehouseName',
  NextReplenishment = 'replenishmentTime',
  Coverage = 'coverage',
  ExpectedCoverage = 'expectedCoverage',
  Constraints = 'constraints',
}

interface State {
  columnsVisibility: Record<string, boolean>;
  columnsOrder: string[];
  width: number;
  readonly __v: number;
}

function getState(): State {
  return {
    columnsVisibility: {
      [Columns.SkuChart]: true,
      [Columns.Size]: true,
      [Columns.SkuName]: false,
      [Columns.SkuExternalId]: false,
      [Columns.Replenishment]: true,
      [Columns.Sold]: true,
      [Columns.SalesRate]: false,
      [Columns.Stock]: true,
      [Columns.OptimalStock]: true,
      [Columns.WarehouseInventory]: false,
      [Columns.WhNames]: false,
      [Columns.NextReplenishment]: false,
      [Columns.Coverage]: false,
      [Columns.ExpectedCoverage]: false,
      [Columns.Constraints]: true,
    },
    columnsOrder: [
      Columns.SkuChart,
      Columns.Size,
      Columns.SkuName,
      Columns.SkuExternalId,
      Columns.Replenishment,
      Columns.Sold,
      Columns.SalesRate,
      Columns.Stock,
      Columns.OptimalStock,
      Columns.WarehouseInventory,
      Columns.WhNames,
      Columns.NextReplenishment,
      Columns.Coverage,
      Columns.ExpectedCoverage,
      Columns.Constraints,
    ],
    width: 680,
    __v: 0,
  };
}

function getSchema() {
  const defaultState = getState();

  return z.object({
    columnsVisibility: z.object(
      Object.keys(defaultState.columnsVisibility).reduce<ZodRawShape>((acc, key) => {
        acc[key] = z.boolean().catch(defaultState.columnsVisibility[key]);

        return acc;
      }, {}),
    ),
    columnsOrder: z.array(z.string()).catch(defaultState.columnsOrder as Columns[]),
    width: z.number(),
  });
}

const migrations = [
  // v1 - save data from the old app setting store
  function v1(state: any) {
    const oldState = window.localStorage.getItem('onebeat-app:app-settings');

    if (!oldState) {
      return {};
    }

    const parsedState = JSON.parse(oldState);

    const { visibleColumns, width } = parsedState.pages.replenishmentStoreDetails;

    return {
      ...state,
      columnsVisibility: {
        [Columns.SkuChart]: visibleColumns.includes(Columns.SkuChart),
        [Columns.Size]: visibleColumns.includes(Columns.Size),
        [Columns.SkuName]: visibleColumns.includes(Columns.SkuName),
        [Columns.SkuExternalId]: visibleColumns.includes(Columns.SkuExternalId),
        [Columns.Replenishment]: visibleColumns.includes(Columns.Replenishment),
        [Columns.Sold]: visibleColumns.includes(Columns.Sold),
        [Columns.SalesRate]: visibleColumns.includes(Columns.SalesRate),
        [Columns.Stock]: visibleColumns.includes(Columns.Stock),
        [Columns.OptimalStock]: visibleColumns.includes(Columns.OptimalStock),
        [Columns.WarehouseInventory]: visibleColumns.includes(Columns.WarehouseInventory),
        [Columns.WhNames]: visibleColumns.includes(Columns.WhNames),
        [Columns.NextReplenishment]: visibleColumns.includes(Columns.NextReplenishment),
        [Columns.Coverage]: visibleColumns.includes(Columns.Coverage),
        [Columns.ExpectedCoverage]: visibleColumns.includes(Columns.ExpectedCoverage),
        [Columns.Constraints]: visibleColumns.includes(Columns.Constraints),
      },
      width,
    };
  },
];

export const useReplenishmentLocationDetailsPageStore = defineStore(
  StoreNames.ReplenishmentLocationDetailsPage,
  {
    state: getState,
    persist: {
      afterRestore: ({ store }) => {
        // migration
        for (let i = store.__v; i < migrations.length; i++) {
          if (migrations[i]) {
            try {
              store.$state = migrations[i]({ ...store.$state });
            } catch (err) {
              // TODO: log?
            }
            store.__v = i + 1;
          }
        }

        const schema = getSchema();
        store.$state = schema.parse(store.$state);
        store.$persist();
      },
    },
  },
);
