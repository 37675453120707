import { getCurrentInstance } from 'vue';
import type { Plugin } from 'vue';
import {
  UnleashClient,
  plugin,
  useUnleashClient as _useUnleashClient,
} from '@unleash/proxy-client-vue';
import type { IConfig } from '@unleash/proxy-client-vue';

let unleashClient: UnleashClient;

type CreateUnleashOptions = IConfig & { startClient?: boolean };

export function createUnleash(options: CreateUnleashOptions): Plugin {
  unleashClient = new UnleashClient(options);

  return {
    install(app) {
      app.use(plugin, { unleashClient, startClient: options.startClient ?? false });
    },
  };
}

export function useUnleashClient(): UnleashClient {
  const client = getCurrentInstance() ? _useUnleashClient() : unleashClient;

  if (!client) {
    throw new Error('`useUnleashClient()` was called with no installed Unleash');
  }

  return client;
}

export * from './feature-flags';
export { useFlag, useFlagsStatus, useUnleashContext, useVariant } from '@unleash/proxy-client-vue';
