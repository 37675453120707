<script lang="ts" setup>
import { watch, onBeforeUnmount } from 'vue';
import { ObAlert, ObAlertButton } from '../../components';
import type { Toast } from './shared';
import { useToaster } from './use-toaster';

interface Props {
  toast: Toast;
}

const props = defineProps<Props>();

const { remove } = useToaster();

let timerId: ReturnType<typeof setTimeout>;

function hide() {
  clearTimeout(timerId);
  remove(props.toast.id);
}

function clearTimer() {
  clearTimeout(timerId);
}

function setTimer() {
  clearTimeout(timerId);

  if (props.toast.duration > 0) {
    timerId = setTimeout(() => {
      remove(props.toast.id);
    }, props.toast.duration);
  }
}

function onMouseenter(): void {
  if (!props.toast.keepAliveOnHover) {
    return;
  }
  clearTimer();
}

function onMouseleave(): void {
  if (!props.toast.keepAliveOnHover) {
    return;
  }
  setTimer();
}

watch(
  () => props.toast.duration,
  () => setTimer(),
  { immediate: true },
);

onBeforeUnmount(() => {
  clearTimeout(timerId);
});
</script>

<template>
  <ObAlert
    :variant="props.toast.variant"
    :closable="props.toast.closable"
    :loading="props.toast.loading"
    @close="hide()"
    @mouseenter="onMouseenter"
    @mouseleave="onMouseleave"
  >
    {{ props.toast.content }}
    <template v-if="props.toast.icon" #icon>
      <component :is="props.toast.icon" />
    </template>
    <template #buttons>
      <ObAlertButton
        v-for="(action, index) in props.toast.actions"
        :key="index"
        @click="() => action.callback(toast)"
      >
        {{ action.label }}
      </ObAlertButton>
    </template>
  </ObAlert>
</template>
