<script lang="ts" setup>
import { computed, inject, provide } from 'vue';
import { merge } from 'lodash-es';
import type { ObConfig } from '../../shared/types';
import { defaultConfig, CONFIG_INJECTION_KEY } from '../../shared/config';

interface Props {
  config?: ObConfig;
}

const props = withDefaults(defineProps<Props>(), {
  config: undefined,
});

const injectedConfig = inject(CONFIG_INJECTION_KEY, null);

const config = computed<ObConfig>(() =>
  merge({}, defaultConfig, injectedConfig?.value, props.config),
);

provide(CONFIG_INJECTION_KEY, config);
</script>

<template>
  <slot />
</template>
