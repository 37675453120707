import { isNativeKeyboardFocusable } from './is-native-keyboard-focusable';
import { isNativeMouseFocusable } from './is-native-mouse-focusable';

interface ObGetClosestFocusableOptions {
  initial: Element;
  root: Node;
  previous?: boolean;
  keyboard?: boolean;
}

export function obGetClosestFocusable({
  initial,
  root,
  previous = false,
  keyboard = true,
}: ObGetClosestFocusableOptions) {
  if (!root.ownerDocument) {
    return null;
  }

  const check = keyboard ? isNativeKeyboardFocusable : isNativeMouseFocusable;

  // Deprecated but ony this overload works in IE
  // Filter must be a function in IE, other modern browsers are compliant to this format
  const treeWalker = root.ownerDocument.createTreeWalker(root, NodeFilter.SHOW_ELEMENT, (node) =>
    'ownerSVGElement' in node ? NodeFilter.FILTER_REJECT : NodeFilter.FILTER_ACCEPT,
  );

  treeWalker.currentNode = initial;

  while (previous ? treeWalker.previousNode() : treeWalker.nextNode()) {
    if (treeWalker.currentNode instanceof HTMLElement && check(treeWalker.currentNode)) {
      return treeWalker.currentNode;
    }
  }

  return null;
}
