const callbacks: Array<(value: boolean) => void> = [];

let ready = false;

export function useAppReady() {
  function emit() {
    if (ready) {
      return;
    }

    ready = true;

    callbacks.forEach((resolve) => resolve(true));
  }

  function isReady() {
    if (ready) {
      return Promise.resolve();
    }

    return new Promise<boolean>((resolve) => {
      callbacks.push(resolve);
    });
  }

  return {
    emit,
    isReady,
  };
}
