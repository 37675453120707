import { defineStore } from 'pinia';
import { z } from 'zod';
import { StoreNames } from '../../../shared/store-names';

interface State {
  sorting: {
    sortBy: string | null;
    sortOrder: 'asc' | 'desc';
  };
  visibleMetrics: string[];
  readonly __v: number;
}

function getState(): State {
  return {
    sorting: {
      sortBy: 'replenishment',
      sortOrder: 'desc',
    },
    visibleMetrics: ['replenishment', 'sold'],
    __v: 0,
  };
}

function getSchema() {
  const defaultState = getState();

  return z.object({
    visibleMetrics: z.array(z.string()),
    sorting: z.object({
      sortBy: z.string().nullable().catch(defaultState.sorting.sortBy),
      sortOrder: z.enum(['asc', 'desc']).catch(defaultState.sorting.sortOrder),
    }),
  });
}

const migrations = [
  // v1 - save data from the old app setting store
  function v1(state: any) {
    const oldState = window.localStorage.getItem('onebeat-app:app-settings');

    if (!oldState) {
      return {};
    }

    const parsedState = JSON.parse(oldState);

    const { visibleMetrics, sorting } = parsedState.pages.replenishmentStoreOverview;

    return {
      ...state,
      sorting,
      visibleMetrics,
    };
  },
];

export const useReplenishmentOverviewPageStore = defineStore(StoreNames.ReplenishmentOverviewPage, {
  state: getState,
  persist: {
    afterRestore: ({ store }) => {
      // migration
      for (let i = store.__v; i < migrations.length; i++) {
        if (migrations[i]) {
          try {
            store.$state = migrations[i]({ ...store.$state });
          } catch (err) {
            // TODO: log?
          }
          store.__v = i + 1;
        }
      }

      const schema = getSchema();
      store.$state = schema.parse(store.$state);
      store.$persist();
    },
  },
});
