import type { Ref } from 'vue';
import { ref, watch } from 'vue';
import { tryOnScopeDispose, unrefElement } from '@vueuse/core';
import type { Fn, MaybeElementRef } from '@vueuse/core';
import { createFocusTrap } from 'focus-trap';
import type { ActivateOptions, DeactivateOptions, FocusTrap, Options } from 'focus-trap';

export interface UseFocusTrapOptions extends Options {
  immediate?: boolean;
}

export interface UseFocusTrapReturn {
  hasFocus: Ref<boolean>;
  isPaused: Ref<boolean>;
  activate: (opts?: ActivateOptions) => void;
  deactivate: (opts?: DeactivateOptions) => void;
  pause: Fn;
  unpause: Fn;
}

export function useFocusTrap(
  containerRef: MaybeElementRef,
  options: UseFocusTrapOptions = {},
): UseFocusTrapReturn {
  let trap: undefined | FocusTrap;

  const { immediate, ...focusTrapOptions } = options;
  const hasFocus = ref(false);
  const isPaused = ref(false);

  function activate(opts?: ActivateOptions) {
    if (trap) {
      trap.activate(opts);
    }
  }

  function deactivate(opts?: DeactivateOptions) {
    if (trap) {
      trap.deactivate(opts);
    }
  }

  function pause() {
    if (trap) {
      trap.pause();
      isPaused.value = true;
    }
  }

  function unpause() {
    if (trap) {
      trap.unpause();
      isPaused.value = false;
    }
  }

  watch(
    () => unrefElement(containerRef),
    (element) => {
      if (!element) {
        return;
      }

      trap = createFocusTrap(element, {
        ...focusTrapOptions,
        onActivate() {
          hasFocus.value = true;

          // Apply if user provided onActivate option
          if (options.onActivate) {
            options.onActivate();
          }
        },
        onDeactivate() {
          hasFocus.value = false;

          if (options.onDeactivate) {
            options.onDeactivate();
          }
        },
      });

      if (immediate) {
        activate();
      }
    },
    { flush: 'post' },
  );

  tryOnScopeDispose(() => deactivate());

  return {
    hasFocus,
    isPaused,
    activate,
    deactivate,
    pause,
    unpause,
  };
}
