import { Comment, Fragment, Teleport, Suspense } from 'vue';
import type { VNode } from 'vue';

export function validateAsChild(children: VNode[]) {
  // TODO: need to check if child vnode is HTML element, but it's not so easy in Vue 3
  if (
    children?.length !== 1 ||
    children[0].type === Comment ||
    children[0].type === Text ||
    children[0].type === Fragment ||
    children[0].type === Teleport ||
    children[0].type === Suspense
  ) {
    return false;
  }

  return true;
}
