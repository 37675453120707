import { defineStore } from 'pinia';
import { useApi } from '../services/api';
import { StoreNames } from '../shared/store-names';

export const useDataStore = defineStore(StoreNames.Data, {
  state: () => ({
    calculation_done_at: '',
  }),
  actions: {
    async fetch(): Promise<void> {
      const api = useApi();

      const { data } = await api.getUploads({
        page: 1,
        per_page: 1,
      });

      if (!data.length) {
        return;
      }
      this.calculation_done_at = data[0].calculation_done_at || '';
    },
    async clear(): Promise<void> {
      this.$reset();
    },
  },
});
