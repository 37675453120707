<script lang="ts" setup>
import { computed, useSlots, useCssModule, h } from 'vue';
import type { SizeM, SizeL } from '../../shared/types';
import { validateAsChild } from '../../utils';

interface Props {
  asChild?: boolean;
  disabled?: boolean;
  active?: boolean;
  size?: SizeM | SizeL;
  variant?: 'primary' | 'secondary' | 'tertiary';
  width?: 'auto' | 'stretch';
}

const props = withDefaults(defineProps<Props>(), {
  asChild: false,
  disabled: false,
  active: false,
  size: 'm',
  variant: 'primary',
  width: 'auto',
});

const slots = useSlots();
const style = useCssModule();

const attrs = computed(() => ({
  class: [
    style.root,
    {
      [style.sizeL]: props.size === 'l',
      [style.variantSecondary]: props.variant === 'secondary',
      [style.variantTertiary]: props.variant === 'tertiary',
      [style.active]: props.active,
      [style.widthStretch]: props.width === 'stretch',
    },
  ],
}));

defineRender(() => {
  const children = slots.default?.();

  if (props.asChild) {
    if (!children || !validateAsChild(children)) {
      // TODO: add warning?
      return null;
    }

    return h(children[0], attrs.value);
  }

  return h('button', { ...attrs.value, type: 'button', disabled: props.disabled }, children);
});
</script>

<style lang="scss" module>
@use '../../styles/colors';
@use '../../styles/iconography';
@use '../../styles/shared';
@use '../../styles/typography';

.root {
  @include shared.reset-button();
  position: relative;
  display: inline-flex;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: shared.$border-radius-s;
  font-family: typography.$font-family-primary;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  padding: 4px 8px;
  gap: 8px;
  height: 32px;
  background: #7a68e3; // TODO: use token
  color: #fff; // TODO: use token

  &::before {
    @include shared.coverer();
    content: '';
    box-sizing: border-box;
    border: 1px solid transparent;
    border-radius: inherit;
  }

  &:focus-visible {
    background: #5b48cf; // TODO: use token

    &::before {
      border-color: #021148; // TODO: use token
    }
  }

  &:hover {
    background: #5b48cf; // TODO: use token
  }

  &:disabled {
    cursor: not-allowed;
    background: #c2c6d3; // TODO: use token
  }

  &.active {
    background: #513fbc; // TODO: use token
  }

  > svg {
    width: 24px;
    height: 24px;
  }
}

.sizeL {
  height: 44px;
  padding: 10px 12px;
}

.variantSecondary,
.variantTertiary {
  color: #021148; // TODO: use token
  background: #fff;

  &:focus-visible {
    background: #fff;

    &::before {
      border-color: colors.$hyperlink;
    }
  }

  &:hover {
    background: #f1effe; // TODO: use token
  }

  &.active {
    background: #e2d4ff;
  }

  &:disabled {
    color: #c2c6d3;
    background-color: #fff;
  }
}

.variantSecondary {
  &::before {
    border-color: #d7d9e2; // TODO: use token
  }

  &:hover,
  &.active {
    &::before {
      border-color: #9aa0b6; // TODO: use token
    }
  }

  &:disabled::before {
    border-color: #f0f1f4; // TODO: use token
  }
}

.widthStretch {
  width: 100%;
}
</style>
